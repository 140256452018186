<template>
  <section id="campaign-list">
    <b-row>
      <b-col md="10">
        <h1>{{ $t("advertising.Campaigns") }}</h1>
        <h5 class="text-primary">
          {{ $t("advertising.CampaignsList") }}
        </h5>
      </b-col>
      <b-col style="text-align: end;">
        <b-button
          variant="primary"
          @click="createNewCampaign"
        >
          <feather-icon
            icon="FolderPlusIcon"
            class="mr-50"
          />
          <span class="text-nowrap">{{ $t('crtCamp') }}</span>
        </b-button>
      </b-col>
    </b-row>
    <b-row
      v-if="showJumbotron"
      class="mt-5"
    >
      <b-col>
        <b-jumbotron
          bg-variant="transparent"
          border-variant="primary"
          :header="$t('advertising.NotCampaignsTitle')"
        >
          <p class="text-primary">
            {{ $t("advertising.NotCampaignsDescription") }}
          </p>
        </b-jumbotron>
      </b-col>
    </b-row>
    <b-overlay
      v-else
      variant="transparent"
      :show="show"
    >
      <b-row class="mt-2 mb-2">
        <b-col md="6">
          <b-form-input
            v-model="search"
            :placeholder="$t('advertising.CampaignName')"
            type="text"
            name="search"
            @keyup="keyUp($event)"
          />
        </b-col>
        <b-col>
          <b-form-select
            v-model="camSel"
            :disabled="false"
          >
            <b-form-select-option :value="null">
              {{ $t('filtCamp') }}
            </b-form-select-option>
            <b-form-select-option
              v-for="campaing in campTableData"
              :key="campaing.key"
              :value="campaing.key"
            >
              {{ campaing.label }}
            </b-form-select-option>
          </b-form-select>
        </b-col>
        <b-col>
          <b-form-select
            v-model="catSel"
            :disabled="contSel == null ? false : true"
          >
            <b-form-select-option :value="null">
              {{ $t('filterByCat') }}
            </b-form-select-option>
            <b-form-select-option
              v-for="categorie in catTableData"
              :key="categorie.node.id"
              :value="categorie.node.id"
            >
              {{ categorie.node.name }}
            </b-form-select-option>
          </b-form-select>
        </b-col>
        <b-col>
          <b-form-select
            v-model="contSel"
            :disabled="catSel == null ? false : true"
          >
            <b-form-select-option :value="null">
              {{ $t('filterByCont') }}
            </b-form-select-option>
            <b-form-select-option
              v-for="content in contTableData"
              :key="content.node.id"
              :value="content.node.id"
            >
              {{
                content.node.name
              }}
            </b-form-select-option>
          </b-form-select>
        </b-col>
      </b-row>
      <b-table
        id="table"
        class="mt-1"
        :striped="true"
        sticky-header="650px"
        :no-border-collapse="true"
        responsive="sm"
        hover
        fixed
        :items="resSearch"
        show-empty
        :fields="columns"
        @row-clicked="goToAdsList"
      >
        <template #cell(name)="data">
          <div class="text-wrap">
            <span>{{ data.item.name }}</span>
          </div>
        </template>
        <template #cell(type)="data">
          <div class="text-nowrap ">
            <span
              v-if="
                data.item.externalsAdsPenetration === 0
              "
            >{{ $t("advertising.CampaignInternal") }}</span>
            <span v-else>{{ $t("advertising.CampaignExternal") }}</span>
          </div>
        </template>
        <template #cell(startDate)="data">
          <div class="text-nowrap ">
            {{ fechaFormato(data.item.startDate) }}
          </div>
        </template>
        <template #cell(finalDate)="data">
          <div class="text-nowrap ">
            {{ fechaFormato(data.item.finalDate) }}
          </div>
        </template>
        <template #cell(isPriority)="data">
          <div class="text-nowrap ">
            <h4>
              <b-badge
                pill
                :variant="`light-${data.item.isPriority ? 'success' : 'danger'}`"
                class="text-capitalize"
              >
                <feather-icon
                  :icon="data.item.isPriority ? 'CheckIcon' : 'XIcon'"
                  size="20"
                  class="mr-50"
                />
                {{
                  data.item.isPriority
                    ? $t('Yprioridad')
                    : $t('Nprioridad')
                }}
              </b-badge>
            </h4>
          </div>
        </template>
        <template #cell(isActive)="data">
          <div class="text-nowrap ">
            <h4>
              <b-badge
                pill
                :variant="`light-${data.item.isActive ? 'success' : 'danger'}`"
                class="text-capitalize"
              >
                <feather-icon
                  :icon="data.item.isActive ? 'CheckIcon' : 'XIcon'"
                  size="20"
                  class="mr-50"
                />
                {{
                  data.item.isActive
                    ? $t("dataGeneric.active")
                    : $t("dataGeneric.inactive")
                }}
              </b-badge>
            </h4>
          </div>
        </template>
        <template #cell(action)="data">
          <div class="text-nowrap d-flex justify-content-around">
            <feather-icon
              icon="Edit2Icon"
              class="text-success cursor-pointer"
              size="18"
              @click.stop.prevent="routeToEditView(data.item.id)"
            />
            <feather-icon
              icon="DeleteIcon"
              class="text-danger cursor-pointer"
              size="18"
              @click.stop.prevent="deleteFolder(data.item.id)"
            />
          </div>
        </template>
      </b-table>
      <b-pagination
        v-if="rows > perPage"
        v-model="currentPage"
        :total-rows="rows"
        :per-page="perPage"
        size="lg"
        class="pt-1"
        @input="getCampaignsByFilter"
      />
    </b-overlay>
  </section>
</template>
<script>
import * as constants from '@core/utils/constants'
import {
  BBadge,
  BRow,
  BCol,
  BButton,
  VBTooltip,
  BJumbotron,
  BOverlay,
  BFormInput,
  BPagination,
  BTable,
  BFormSelect,
  BFormSelectOption,
} from 'bootstrap-vue'
import axios from '@axios'
import { isUserInGroup, messageError, utf8ToB64 } from '@/store/functions'

// eslint-disable-next-line import/no-extraneous-dependencies
import { isObject } from '@vueuse/shared'
import { getUserData } from '@/auth/utils'

export default {
  components: {
    BFormSelect,
    BFormSelectOption,
    BRow,
    BTable,
    BFormInput,
    BCol,
    BButton,
    BBadge,
    BJumbotron,
    BOverlay,
    BPagination,
  },
  directives: {
    'b-tooltip': VBTooltip,
  },
  data() {
    return {
      campaigns: [],
      resSearch: [],
      search: '',
      showJumbotron: false,
      catTableData: [],
      perPage: 20,
      currentPage: 1,
      rows: null,
      contTableData: [],
      campTableData: [
        { label: 'Interna', key: 'INT' },
        { label: 'Externa', key: 'EXT' },
      ],
      catSel: null,
      isSponsor: false,
      contSel: null,
      camSel: null,
      show: true,
      userData: getUserData(),
      columns: [
        { key: 'name', label: this.$t('firstName'), sortable: true },
        { key: 'type', label: this.$t('code.type'), sortable: false },
        { key: 'startDate', label: this.$t('epg.startDate'), sortable: true },
        { key: 'finalDate', label: this.$t('epg.endDate'), sortable: true },
        { key: 'isPriority', label: this.$t('Yprioridad'), sortable: true },
        { key: 'isActive', label: this.$t('editContent.status'), sortable: true },
        { key: 'action', label: this.$t('message.tableHeader.action') },
      ],
    }
  },
  watch: {
    catSel() {
      this.currentPage = 1
      this.getCampaignsByFilter()
    },
    contSel() {
      this.currentPage = 1
      this.getCampaignsByFilter()
    },
    camSel() {
      this.currentPage = 1
      this.getCampaignsByFilter()
    },
  },
  async created() {
    if (this.userData.groups.edges.length !== 0) {
      this.isSponsor = isUserInGroup(this.userData, constants.sponsor)
    }
    await this.getCampaigns()
  },
  methods: {
    getCampaigns() {
      const query = `
            query{
                allCampaigns(
                ${this.isSponsor ? `creator:"${utf8ToB64(`id:${this.userData.id}`)}",` : ''}                  
                  client:"${this.userData.profile.client.id}") {
                    totalCount
                    edges {
                        node {
                            id
                            name
                            isActive
                            isPriority
                            externalsAdsPenetration
                            contentsFillRate
                            startDate
                            finalDate
                            categories ${this.isSponsor ? `(filter_Sponsor:"${this.userData.id}")` : ''} {
                              edges {
                                node {
                                  id
                                  name
                                }
                              }
                            }
                            contents ${this.isSponsor ? `(creator:"${utf8ToB64(`id:${this.userData.id}`)}")` : ''} {
                              edges {
                                node {
                                  id
                                  name
                                }
                              }
                            }
                            campaingAd{
                                totalCount
                                edgeCount
                                edges{
                                    node {
                                        id
                                        name
                                    }
                                }
                            }
                        }
                    }
                }
            }
            `
      axios.post('', { query }).then(res => {
        messageError(res, this)

        const campaigns = res.data.data.allCampaigns.edges

        campaigns.forEach(element => {
          this.catTableData.push(...element.node.categories.edges)
        })
        this.catTableData = this.catTableData.filter(
          (v, i, a) => a.findIndex(v2 => v2.node.id === v.node.id) === i,
        )
        this.catTableData.sort((a, b) => (a.node.name.toLowerCase() > b.node.name.toLowerCase() ? 1 : -1))

        campaigns.forEach(element => {
          this.contTableData.push(...element.node.contents.edges)
        })

        this.contTableData = this.contTableData.filter(
          (v, i, a) => a.findIndex(v2 => v2.node.id === v.node.id) === i,
        )

        this.contTableData.sort((a, b) => (a.node.name.toLowerCase() > b.node.name.toLowerCase() ? 1 : -1))
        this.showJumbotron = !(campaigns.length > 0)

        this.getCampaignsByFilter()
      })
    },
    keyUp(event) {
      const keycode = event.keyCode || event.which
      if (keycode === 13) {
        this.currentPage = 1
        this.getCampaignsByFilter()
      }
    },
    getCampaignsByFilter() {
      const query = `
            query{
                allCampaigns (
                name:"${this.search}",
                typeCampaign:"${this.camSel}",
                first:${this.perPage},
                offset:${this.perPage * (this.currentPage - 1)},
                orderBy: "name",client:"${this.userData.profile.client.id}",
                ${this.isSponsor ? `creator:"${utf8ToB64(`id:${this.userData.id}`)}",` : ''}     
                ${this.catSel != null ? `categories:"${utf8ToB64(`id:${this.catSel}`)}",` : ''}
                ${this.contSel != null ? `contents:"${utf8ToB64(`id:${this.contSel}`)}",` : ''}){
                  totalCount
                    edges {
                        node {
                            id
                            name
                            isActive
                            isPriority
                            externalsAdsPenetration
                            contentsFillRate
                            startDate
                            finalDate
                            categories ${this.isSponsor ? `(filter_Sponsor:"${this.userData.id}")` : ''} {
                              edges {
                                node {
                                  id
                                  name
                                }
                              }
                            }
                            contents ${this.isSponsor ? `(creator:"${utf8ToB64(`id:${this.userData.id}`)}")` : ''} {
                              edges {
                                node {
                                  id
                                  name
                                }
                              }
                            }
                            campaingAd{
                                totalCount
                                edgeCount
                                edges{
                                    node {
                                        id
                                        name
                                    }
                                }
                            }
                        }
                    }
                }
            }
            `
      axios.post('', { query }).then(res => {
        messageError(res, this)

        this.campaigns = res.data.data.allCampaigns.edges
        this.rows = res.data.data.allCampaigns.totalCount

        const campaigns = res.data.data.allCampaigns.edges
        this.campaigns = []
        campaigns.forEach(element => {
          this.campaigns.push(element.node)
        })
        this.show = false
        this.resSearch = [...this.campaigns]
        this.evalueData()
      })
    },
    evalueData() {
      let query = 'mutation{'
      let update = 0

      this.campaigns.forEach((item, index) => {
        const contentFill = item.contentsFillRate
        const externalAds = item.externalsAdsPenetration
        if (
          !((contentFill === 0 && externalAds > 0 && externalAds <= 1)
            || (externalAds === 0 && contentFill > 0 && contentFill <= 1))
        ) {
          update += 1

          query += `
          up${index + 1}: updateCampaign(id: ${item.id}, input: {
                            externalsAdsPenetration: ${item.campaingAd.totalCount === 0 ? 1 : 0},
                            contentsFillRate: ${item.campaingAd.totalCount === 0 ? 0 : 1}
                            }){
                            campaing {
                                id
                                contentsFillRate
                                externalsAdsPenetration
                                startDate
                                finalDate
                                isPriority

                            }
                            }`
        }
      })
      query += '}'

      if (update > 0) {
        axios
          .post('', {
            query,
          }).then(result => {
            messageError(result, this)

            this.getCampaignsByFilter()
          }).catch(err => {
            console.log(err)
          })
      }
    },
    fechaFormato(value) {
      const date = new Date(value)
      const options = {
        year: 'numeric',
        month: 'numeric',
        day: 'numeric',
        hour: 'numeric',
        minute: 'numeric',
        second: 'numeric',
        hour12: false,
        timeZone: Intl.DateTimeFormat().resolvedOptions().timeZone,
      }

      return value != null
        ? new Intl.DateTimeFormat('default', options).format(date)
        : value
    },

    createNewCampaign() {
      this.$router.push('/advertising/campaigns/create')
    },
    goToAdsList(data) {
      const { id } = data

      const externo = data.externalsAdsPenetration
      const isExternal = externo !== 0
      localStorage.setItem('isExternal', isExternal)
      if (isExternal) {
        this.$router.push({ name: 'campaigns-edit', params: { id } })
      } else {
        this.$router.push({ name: 'ads-list', params: { campaign: id } })
      }
    },
    deleteFolder(id) {
      this.$swal({
        title: this.$t('code.title'),
        text: this.$t('code.text'),
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: this.$t('code.confirm'),
        cancelButtonText: this.$t('dataGeneric.cancel'),
        customClass: {
          confirmButton: 'btn btn-primary',
          cancelButton: 'btn btn-outline-danger ml-1',
        },
        buttonsStyling: false,
      }).then(result => {
        if (result.value) {
          axios
            .post('', {
              query: `
              mutation{
                deleteCampaign(id:"${id}") {
                  found
                  deletedId
                }
              }`,
            })
            .then(res => {
              messageError(res, this)

              this.resSearch = this.resSearch.filter(item => item.id !== id)
              this.campaigns = this.campaigns.filter(item => item.id !== id)
            })
        }
      })
    },
    routeToEditView(campaignId) {
      let id = 0
      if (isObject(campaignId)) {
        id = campaignId.id
      } else {
        id = campaignId
      }
      this.$router.push({
        name: 'campaigns-edit',
        params: { id },
      })
    },
    fetchData(folder) {
      this.$refs.categoryCard.fetchData(null, folder)
    },
  },
}
</script>
<style lang="scss" scoped>
#campaign-list .card-body {
  padding: 1rem;
  -webkit-box-flex: 1;
  flex: 1 1 auto;
  min-height: 1px;
}

#campaign-list .card-body h4 {
  font-size: 1.286rem !important;
}

#campaign-list .card-img-top {
  /*width: 100%;
  height: 25vh;
  object-fit: cover;*/
}

#campaign-list .actions {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  background: rgb(0 15 51 / 73%);
  opacity: 1;
  transition: opacity 0.2s ease-in-out;
}

#campaign-list .card img {
  /*width: 100%;
    height:  100%;
    object-fit: cover;*/
}

#campaign-list .card-body {
  padding: 0rem;
}

#campaign-list .card-body h4 {
  font-size: 1rem !important;
}

#campaign-list .card-body .actions {
  display: -webkit-flex;
  display: flex;
  transition: all 0.2s ease-in-out;
  position: absolute;
  top: 0;
  left: 0;
  z-index: 100;
  width: 100%;
  height: 2.75rem;
  padding-right: 0.34375rem;
  -webkit-flex-direction: row;
  flex-direction: row;
  -webkit-align-items: center;
  align-items: center;
  -webkit-justify-content: flex-end;
  justify-content: flex-end;
}
#acortarPadre {
  overflow: hidden;
  text-overflow: ellipsis;

  white-space: nowrap;
}
#campaign-list .text-wrap {
  white-space: normal; /* Permite que el texto haga saltos de línea */
  word-wrap: break-word; /* Divide las palabras largas */
  word-break: break-word; /* Rompe las palabras largas para que se ajusten */
  overflow-wrap: break-word; /* Manejo adicional para texto largo */
}
</style>
